.search-results {
  .search-results-search {
    background: transparent !important;

    input[type='search'] {
      border: 1px solid $form-field-border-color !important;
      border-radius: 50px;
    }

    .search-submit {
      padding: 15px;
      background: $button-color;
      border-radius: 50px;
      color: white;
      text-decoration: none;
      letter-spacing: 1px;
      transition: .5s;

      @include medium-up {
        padding: 15px 60px;
      }

      &:hover,
      &:focus {
        border-color: white;
        background: $primary-darker-color;
        text-decoration: none;
      }

      &:visited {
        color: white;
      }
    }
  }

  .results {
    &:before {
      content: none;
    }
  }
}