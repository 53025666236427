.sidebar-layout {
  overflow-x: hidden;

  main {
    padding: 0 !important;
    display: flex;
    flex-wrap: wrap;

    .main-content {
      width: 100%;
      padding: 65px 20px 30px;
      @include medium-up {
        width: 70%;
        padding: 100px 70px;
      }
    }

    .sidebar-content {
      width: 100%;
      background: $sidebar-background;
      padding: 30px 20px;
      @include medium-up {
        padding: 100px 40px 100px 55px;
        width: 30%;
        position: relative;

        &:before {
          content: '';
          background: $sidebar-background;
          width: 100vw;
          height: 100%;
          position: absolute;
          display: inline-block;
          z-index: 0;
          top: 0;
        }
      }
    }
  }

  &.left {
    main {
      .main-content {
        order: 1;
        @include medium-up {
          order: 2;
        }
      }

      .sidebar-content {
        order: 2;

        &:before {
          right: 100%;
        }
        @include medium-up {
          order: 1;
        }
      }
    }
  }

  &.right {
    main {
      .main-content {
        order: 1;
      }

      .sidebar-content {
        order: 2;

        &:before {
          left: 100%;
        }
      }
    }
  }

  &.two {
    .main-content {
      order: 1;
      width: 100%;
      padding: 65px 20px 30px;
      @include medium-up {
        order: 2;
        width: 50%;
        padding: 100px 70px;
      }
    }

    .sidebar-content {
      width: 100%;
      padding: 30px 20px;
      @include medium-up {
        padding: 100px 40px 100px 55px;
        width: 25%;
      }

      &.left {
        order: 2;

        &:before {
          right: 100%;
        }
        @include medium-up {
          order: 1;
        }
      }

      &.right {
        order: 3;

        &:before {
          left: 100%;
        }
        @include medium-up {
          order: 3;
        }
      }
    }
  }
}