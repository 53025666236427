.events-list-view {
  position: relative;

  .event-month {
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
    display: block;
    padding-top: 25px;
    padding-bottom: 20px;
    font-size: 30px;

    &:first-of-type {
      padding-top: 0;
    }
  }

  .event {
    width: 100%;
    border-bottom: 2px dashed $news-item-border-bottom-color;
    padding-bottom: 25px;

    &:last-of-type {
      border: none;
    }

    .event-title {
      margin-bottom: 5px;

      a {
        border: none;
        color: $color01;

        &:visited {
          color: $color01;
        }
      }
    }

    p {
      margin: 30px 0;
    }

    .event-image {
      display: none;
    }

    .event-read-more {
      color: $color02;
      font-weight: 700;

      &:hover,
      &:focus {
        color: black;
      }
    }
  }
}

.event-details {
  display: block;

  .event-details-image {
    max-width: 325px;
    margin-bottom: 20px;
    @include medium-up {
      margin-right: 40px;
      flex: 0 0 325px;
    }

    img {
      width: 100%;
    }
  }

  .event-details-title {
    color: #333333;
    @include medium-up {
      margin-top: 0;
      line-height: 24px;
    }
  }

  .event-details-date span {
    display: inline-block;
    font-size: 18px;
    letter-spacing: 1px;
    margin-bottom: 5px;
    font-weight: 600;
    text-transform: uppercase;

    span {
      color: #999999;
    }
  }

  .event-details-location {
    border-bottom: 2px solid $events-item-border-bottom-color;
    padding-bottom: 25px;
    margin-bottom: 35px;
  }

  .returnlink {
    border-top: 2px solid $events-item-border-bottom-color;
    margin-top: 40px;
    padding-top: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;

    span {
      margin-right: 5px;
    }
  }

  a {
    border: none;
    cursor: pointer;

    &:hover,
    &:focus {
      border-bottom: 1px solid $link-border-color;
    }

    &:visited {
      color: $color01;
    }
  }
}

.events-month-nav {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 0 0 30px;
  border-bottom: 1px solid $events-featured-heading;
  margin-bottom: 30px;
  text-align: center;
  @include medium-up {
    justify-content: space-between;
  }

  .month-year {
    color: black;
    font-size: 30px;
    font-weight: 700;
    margin: 20px 0;
    @include medium-up {
      margin: 0;
    }
  }

  a {
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 700;

    &.prev-month,
    &.next-month {
      &:before {
        content: '';
        position: relative;
        display: block;
        font-family: 'Font Awesome 5 Free', 'Font Awesome 5 Pro', FontAwesome;
        font-size: 20px;
        font-weight: 900;

        @media (min-width: 990px) {
          display: none;
        }
      }

      span {
        display: none;

        @media (min-width: 990px) {
          display: block;
        }
      }
    }

    &.prev-month {
      &:before {
        content: '\f053';
      }
    }

    &.next-month {
      &:before {
        content: '\f054';
      }
    }
  }
}

.events-calendar {
  td.day {
    width: 100% !important;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    @media (min-width: 990px) {
      display: table-cell;
      width: auto !important;
      text-align: left;
    }
  }

  .cell-heading {
    display: inline-block;
    width: auto !important;
    // padding-right: 10px;
    min-width: 30px;
    text-align: left;
  }

  .cell-content {
    display: inline-flex !important;
    width: 100% !important;
    align-items: center;

    @media (min-width: 990px) {
      display: block !important;
      width: auto !important;
    }

    span.date {
      width: 100%;
      max-width: 50px;
    }

    .day-content {
      width: 100%;
      display: inline-flex;
      align-items: center;
      text-align: left;

      &:before {
        content: '|';
        position: relative;
        display: block;
        padding-right: 15px;

        @media (min-width: 990px) {
          display: none;
        }
      }

      a {
        display: block;
      }
    }
  }
}
