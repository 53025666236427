.gallery,
.albums {
  margin: 0 -15px;

  h2 {
    padding-left: 0 !important;
    border-bottom: 1px solid $events-featured-heading;
    padding-bottom: 15px !important;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  // h2,
  // p {
  //   display: none;
  // }
  .albums .album,
  .album .photos .photos {
    @include medium-up {
      width: calc(100% / 3) !important;
    }
  }

  .album,
  .photos {
    @include medium-up {
      justify-content: flex-start;
    }

    a {
      width: auto !important;
      height: auto !important;
    }

    .photo img,
    img {
      width: 100% !important;
      top: 0 !important;
      transform: none !important;
    }

    &:after {
      content: "";
      flex: auto;
    }
  }
}

.album {
  h2 {
    padding-left: 0 !important;
  }

  .photos {
    margin: 0 -15px;
  }

  .album-name {
    color: $color02;
    margin: 10px 0;
    font-size: 18px;
    font-weight: 700;
  }
}