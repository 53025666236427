/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: black;
  background: white;
}

.offscreen {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.hide {
  display: none;
}

.show {
  display: initial;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: "";
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media (orientation: landscape) {
  .show-landscape {
    display: block;
  }
}
@media (orientation: portrait) {
  .show-landscape {
    display: none;
  }
}

@media (orientation: portrait) {
  .show-portrait {
    display: block;
  }
}
@media (orientation: landscape) {
  .show-portrait {
    display: none;
  }
}

@media (orientation: landscape) {
  .hide-landscape {
    display: none;
  }
}
@media (orientation: portrait) {
  .hide-landscape {
    display: block;
  }
}

@media (orientation: portrait) {
  .hide-portrait {
    display: none;
  }
}
@media (orientation: landscape) {
  .hide-portrait {
    display: block;
  }
}

@media (min-width: 991px) {
  .show-small-only {
    display: none;
  }
}

@media (min-width: 0) and (max-width: 990px) {
  .show-medium-only {
    display: none;
  }
}
@media (min-width: 1024px) {
  .show-medium-only {
    display: none;
  }
}

.show-large-only {
  display: none;
}
@media (min-width: 1024px) and (max-width: 1340px) {
  .show-large-only {
    display: block;
  }
}
@media (min-width: 1341px) {
  .show-large-only {
    display: none;
  }
}

.show-xlarge-only {
  display: none;
}
@media (min-width: 1341px) and (max-width: 1399px) {
  .show-xlarge-only {
    display: block;
  }
}
@media (min-width: 1400px) {
  .show-xlarge-only {
    display: none;
  }
}

.show-xxlarge-only {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-only {
    display: none;
  }
}

.show-medium-up {
  display: none;
}
@media (min-width: 991px) {
  .show-medium-up {
    display: block;
  }
}

.show-large-up {
  display: none;
}
@media (min-width: 1024px) {
  .show-large-up {
    display: block;
  }
}

.show-xlarge-up {
  display: none;
}
@media (min-width: 1341px) {
  .show-xlarge-up {
    display: block;
  }
}

.show-xxlarge-up {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-up {
    display: block;
  }
}

@media (min-width: 0) and (max-width: 990px) {
  .hide-small-only {
    display: none;
  }
}

@media (min-width: 991px) and (max-width: 1023px) {
  .hide-medium-only {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1340px) {
  .hide-large-only {
    display: none;
  }
}

@media (min-width: 1341px) and (max-width: 1399px) {
  .hide-xlarge-only {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-only {
    display: none;
  }
}

@media (min-width: 991px) {
  .hide-medium-up {
    display: none;
  }
}

@media (min-width: 1024px) {
  .hide-large-up {
    display: none;
  }
}

@media (min-width: 1341px) {
  .hide-xlarge-up {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-up {
    display: none;
  }
}

.accordions {
  margin-bottom: 20px;
}
.accordions .accordion-title {
  position: relative;
}
.accordions .accordion-title button {
  position: relative;
  width: 100%;
  margin: 0 0 10px 0;
  padding: 20px 82px 10px 0;
  text-align: left;
  color: black;
  border: 0;
  border-bottom: 2px solid gray;
  background: transparent;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  letter-spacing: normal;
}
.accordions .accordion-title button span {
  position: absolute;
  right: 0;
  bottom: -2px;
  width: 80px;
  height: calc(100% + 2px);
  border-bottom: 2px solid gray;
}
.accordions .accordion-title button span:after {
  position: absolute;
  top: 50%;
  left: 32px;
  content: "\f067";
  transform: translateY(-8px);
  text-align: center;
  text-decoration: inherit;
  color: black;
  font-family: FontAwesome;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
}
.accordions .accordion-title button:hover {
  cursor: pointer;
}
.accordions .accordion-title button:hover, .accordions .accordion-title button:focus {
  color: blue;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  background: transparent;
}
.accordions .accordion-title button:hover span, .accordions .accordion-title button:focus span {
  border-bottom: 2px solid gray;
}
.accordions .accordion-title.is-active button, .accordions .accordion-title.is-active button:focus {
  color: blue;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  background: transparent;
}
.accordions .accordion-title.is-active button span, .accordions .accordion-title.is-active button:focus span {
  border-bottom: 2px solid gray;
}
.accordions .accordion-title.is-active button span:after, .accordions .accordion-title.is-active button:focus span:after {
  content: "\f068";
}
.accordions .accordion-content[aria-hidden] {
  display: none;
}
.accordions .accordion-content[aria-hidden=false] {
  display: block;
}
.accordions.active h2 {
  margin: 0;
}
.sidebar-layout {
  overflow-x: hidden;
}
.sidebar-layout main {
  padding: 0 !important;
  display: flex;
  flex-wrap: wrap;
}
.sidebar-layout main .main-content {
  width: 100%;
  padding: 65px 20px 30px;
}
@media (min-width: 991px) {
  .sidebar-layout main .main-content {
    width: 70%;
    padding: 100px 70px;
  }
}
.sidebar-layout main .sidebar-content {
  width: 100%;
  background: #ffffff;
  padding: 30px 20px;
}
@media (min-width: 991px) {
  .sidebar-layout main .sidebar-content {
    padding: 100px 40px 100px 55px;
    width: 30%;
    position: relative;
  }
  .sidebar-layout main .sidebar-content:before {
    content: "";
    background: #ffffff;
    width: 100vw;
    height: 100%;
    position: absolute;
    display: inline-block;
    z-index: 0;
    top: 0;
  }
}
.sidebar-layout.left main .main-content {
  order: 1;
}
@media (min-width: 991px) {
  .sidebar-layout.left main .main-content {
    order: 2;
  }
}
.sidebar-layout.left main .sidebar-content {
  order: 2;
}
.sidebar-layout.left main .sidebar-content:before {
  right: 100%;
}
@media (min-width: 991px) {
  .sidebar-layout.left main .sidebar-content {
    order: 1;
  }
}
.sidebar-layout.right main .main-content {
  order: 1;
}
.sidebar-layout.right main .sidebar-content {
  order: 2;
}
.sidebar-layout.right main .sidebar-content:before {
  left: 100%;
}
.sidebar-layout.two .main-content {
  order: 1;
  width: 100%;
  padding: 65px 20px 30px;
}
@media (min-width: 991px) {
  .sidebar-layout.two .main-content {
    order: 2;
    width: 50%;
    padding: 100px 70px;
  }
}
.sidebar-layout.two .sidebar-content {
  width: 100%;
  padding: 30px 20px;
}
@media (min-width: 991px) {
  .sidebar-layout.two .sidebar-content {
    padding: 100px 40px 100px 55px;
    width: 25%;
  }
}
.sidebar-layout.two .sidebar-content.left {
  order: 2;
}
.sidebar-layout.two .sidebar-content.left:before {
  right: 100%;
}
@media (min-width: 991px) {
  .sidebar-layout.two .sidebar-content.left {
    order: 1;
  }
}
.sidebar-layout.two .sidebar-content.right {
  order: 3;
}
.sidebar-layout.two .sidebar-content.right:before {
  left: 100%;
}
@media (min-width: 991px) {
  .sidebar-layout.two .sidebar-content.right {
    order: 3;
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}
@media (min-width: 991px) {
  img {
    max-width: 100%;
  }
}
img[class*=left] {
  float: left;
  max-width: 40%;
  margin: 0 20px 15px 0;
}
@media (min-width: 991px) {
  img[class*=left] {
    margin: 0 50px 20px 0;
  }
}
img[class*=right] {
  float: right;
  max-width: 40%;
  margin: 0 0 15px 20px;
}
@media (min-width: 991px) {
  img[class*=right] {
    margin: 0 0 20px 50px;
  }
}
img[class*=center] {
  margin: 0 auto 15px auto;
}
@media (min-width: 991px) {
  img[class*=center] {
    margin: 0 auto 20px auto;
  }
}

figure {
  display: block;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}
@media (min-width: 991px) {
  figure {
    max-width: 100%;
  }
}
figure img {
  display: block;
  margin: 0 auto;
}
figure img[class*=align] {
  margin: 0 auto;
  float: none;
}
@media (min-width: 991px) {
  figure img[class*=align] {
    margin: 0 auto;
    float: none;
  }
}
figure[class*=left] {
  float: left;
  max-width: 40%;
  margin: 0 20px 15px 0;
}
@media (min-width: 991px) {
  figure[class*=left] {
    margin: 0 50px 20px 0;
  }
}
figure[class*=right] {
  float: right;
  max-width: 40%;
  margin: 0 0 15px 20px;
}
@media (min-width: 991px) {
  figure[class*=right] {
    margin: 0 0 20px 50px;
  }
}
figure[class*=center] {
  margin: 0 auto 15px auto;
}
@media (min-width: 991px) {
  figure[class*=center] {
    margin: 0 auto 20px auto;
  }
}
figure figcaption {
  margin-top: 1px;
  font-size: 15px;
  line-height: 18px;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  color: white;
  background: black;
  padding: 10px 25px;
}

.subpage {
  position: relative;
  overflow-x: hidden;
}
.subpage main {
  padding: 65px 20px 80px;
  position: relative;
}
.subpage main h1 {
  margin-top: 0;
}
@media (min-width: 991px) {
  .subpage main {
    padding: 100px 50px;
    max-width: 1600px;
    justify-content: center;
    margin: 0 auto;
  }
}

.banner-wrapper {
  background: url("/themes/athena/assets/dist/images/subpage-banner.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  height: 250px;
  position: relative;
  width: 100%;
}
.banner-wrapper:before {
  content: "";
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
}
.banner-wrapper .title {
  margin: auto;
  position: absolute;
  padding: 0 20px;
  margin: auto;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100%;
}
@media (min-width: 991px) {
  .banner-wrapper .title {
    padding: 0;
  }
}
.banner-wrapper .title h1 {
  font-weight: 700;
  font-size: 48px;
  color: #037a8b;
}
@media (min-width: 991px) {
  .banner-wrapper .title h1 {
    max-width: 1600px;
    padding: 0 50px;
    margin: auto;
    width: 100%;
  }
}

@media (min-width: 991px) {
  table.responsive-table {
    border-radius: 5px;
    border-collapse: inherit;
    border-spacing: 0;
    overflow: hidden;
    width: 100%;
  }
}
table.responsive-table .cell-heading {
  display: inline-block;
  width: 30%;
  font-weight: 700;
}
@media (min-width: 991px) {
  table.responsive-table .cell-heading {
    display: none;
  }
}
table.responsive-table .cell-content {
  display: inline-block;
  width: 70%;
}
@media (min-width: 991px) {
  table.responsive-table .cell-content {
    display: block;
  }
}
table.responsive-table thead {
  background-color: #037a8b;
}
table.responsive-table thead tr th {
  color: #ffffff;
  text-transform: none;
  font-size: 18px;
  font-weight: 700;
  padding: 20px;
  border: 1px solid #026a77;
  text-align: left;
}
@media (min-width: 991px) {
  table.responsive-table thead tr th {
    padding: 20px;
    border: 2px solid #026a77;
  }
  table.responsive-table thead tr th:nth-of-type(even) {
    border-left: 0;
    border-right: 0;
  }
  table.responsive-table thead tr th:last-of-type {
    border-right: 2px solid #026a77;
  }
}
table.responsive-table tbody tr:last-child {
  border-bottom: 0;
}
table.responsive-table tbody tr td {
  padding: 20px 0;
  border-bottom: 2px solid #d9d9d9;
  font-size: 16px;
}
@media (min-width: 991px) {
  table.responsive-table tbody tr td {
    border-right: 2px solid #d9d9d9;
    padding: 20px;
    border-left: 2px solid #d9d9d9;
  }
  table.responsive-table tbody tr td:nth-of-type(even) {
    border-left: 0;
    border-right: 0;
  }
  table.responsive-table tbody tr td:last-of-type {
    border-right: 2px solid #d9d9d9;
  }
}

.tabs .tab-con {
  margin-bottom: 0;
  z-index: 1;
}
.tabs .tab-con .tab-list li:before {
  display: none;
}
.tabs .tab-con .tab-list li a {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #ececec;
  border-left: 2px solid #d9d9d9;
  border-top: 2px solid #d9d9d9;
  border-right: 2px solid #d9d9d9;
  padding: 15px 35px;
}
.tabs .tab-con .tab-list li a:before {
  content: "";
  background: #d9d9d9;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 2px;
  left: 0;
}
.tabs .tab-con .tab-list li a:hover, .tabs .tab-con .tab-list li a:focus {
  background: #ececec;
  color: #333333;
}
.tabs .tab-con .tab-list li a[aria-selected] {
  background: #037a8b;
  color: white;
  border-color: #037a8b;
}
.tabs .tab-con .tab-list li a[aria-selected]:before {
  background: #037a8b;
}
.tabs .tab-con .tab-list li a[aria-selected]:hover, .tabs .tab-con .tab-list li a[aria-selected]:focus {
  background: #037a8b;
  color: white;
}
.tabs .tab-con .tab-list li a[aria-selected][role=tab]:focus {
  background: #037a8b;
  color: white;
}
.tabs .tab-content {
  background: white;
  padding: 25px 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
  z-index: 0;
}
.tabs .tab-content:before {
  content: "";
  background: #d9d9d9;
  position: absolute;
  width: 100%;
  top: -2px;
  height: 2px;
  left: 0;
}
@media (min-width: 991px) {
  .tabs .tab-content {
    padding: 55px 0 25px;
  }
}

.accordions h2.accordion-title button {
  font-size: 20px;
  font-weight: 700;
  padding: 20px 0 10px 40px;
  line-height: 24px;
  color: #037a8b;
  border: 0;
}
.accordions h2.accordion-title button:after {
  content: "\f055";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  left: 0;
  font-weight: 700;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  font-size: 24px;
  display: inline-flex;
  align-items: center;
  color: #037a8b;
}
.accordions h2.accordion-title button:hover, .accordions h2.accordion-title button:focus {
  color: #333333;
  border: 0;
}
.accordions h2.accordion-title button:hover:after, .accordions h2.accordion-title button:focus:after {
  color: #333333;
}
.accordions h2.accordion-title button span {
  display: none;
}
.accordions h2.accordion-title.is-active button {
  border: 0;
  color: #037a8b !important;
}
.accordions h2.accordion-title.is-active button:after {
  content: "\f056";
  color: #037a8b;
}
.accordions h2.accordion-title.is-active button:focus {
  color: #037a8b;
  border: 0;
}

.search-results .search-results-search {
  background: transparent !important;
}
.search-results .search-results-search input[type=search] {
  border: 1px solid #cccccc !important;
  border-radius: 50px;
}
.search-results .search-results-search .search-submit {
  padding: 15px;
  background: #e46e2e;
  border-radius: 50px;
  color: white;
  text-decoration: none;
  letter-spacing: 1px;
  transition: 0.5s;
}
@media (min-width: 991px) {
  .search-results .search-results-search .search-submit {
    padding: 15px 60px;
  }
}
.search-results .search-results-search .search-submit:hover, .search-results .search-results-search .search-submit:focus {
  border-color: white;
  background: #026a77;
  text-decoration: none;
}
.search-results .search-results-search .search-submit:visited {
  color: white;
}
.search-results .results:before {
  content: none;
}

.events-list-view {
  position: relative;
}
.events-list-view .event-month {
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
  display: block;
  padding-top: 25px;
  padding-bottom: 20px;
  font-size: 30px;
}
.events-list-view .event-month:first-of-type {
  padding-top: 0;
}
.events-list-view .event {
  width: 100%;
  border-bottom: 2px dashed #e6e6e6;
  padding-bottom: 25px;
}
.events-list-view .event:last-of-type {
  border: none;
}
.events-list-view .event .event-title {
  margin-bottom: 5px;
}
.events-list-view .event .event-title a {
  border: none;
  color: #161925;
}
.events-list-view .event .event-title a:visited {
  color: #161925;
}
.events-list-view .event p {
  margin: 30px 0;
}
.events-list-view .event .event-image {
  display: none;
}
.events-list-view .event .event-read-more {
  color: #037a8b;
  font-weight: 700;
}
.events-list-view .event .event-read-more:hover, .events-list-view .event .event-read-more:focus {
  color: black;
}

.event-details {
  display: block;
}
.event-details .event-details-image {
  max-width: 325px;
  margin-bottom: 20px;
}
@media (min-width: 991px) {
  .event-details .event-details-image {
    margin-right: 40px;
    flex: 0 0 325px;
  }
}
.event-details .event-details-image img {
  width: 100%;
}
.event-details .event-details-title {
  color: #333333;
}
@media (min-width: 991px) {
  .event-details .event-details-title {
    margin-top: 0;
    line-height: 24px;
  }
}
.event-details .event-details-date span {
  display: inline-block;
  font-size: 18px;
  letter-spacing: 1px;
  margin-bottom: 5px;
  font-weight: 600;
  text-transform: uppercase;
}
.event-details .event-details-date span span {
  color: #999999;
}
.event-details .event-details-location {
  border-bottom: 2px solid #e6e6e6;
  padding-bottom: 25px;
  margin-bottom: 35px;
}
.event-details .returnlink {
  border-top: 2px solid #e6e6e6;
  margin-top: 40px;
  padding-top: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.event-details .returnlink span {
  margin-right: 5px;
}
.event-details a {
  border: none;
  cursor: pointer;
}
.event-details a:hover, .event-details a:focus {
  border-bottom: 1px solid rgba(213, 213, 213, 0.5);
}
.event-details a:visited {
  color: #161925;
}

.events-month-nav {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 0 0 30px;
  border-bottom: 1px solid #037a8b;
  margin-bottom: 30px;
  text-align: center;
}
@media (min-width: 991px) {
  .events-month-nav {
    justify-content: space-between;
  }
}
.events-month-nav .month-year {
  color: black;
  font-size: 30px;
  font-weight: 700;
  margin: 20px 0;
}
@media (min-width: 991px) {
  .events-month-nav .month-year {
    margin: 0;
  }
}
.events-month-nav a {
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 700;
}
.events-month-nav a.prev-month:before, .events-month-nav a.next-month:before {
  content: "";
  position: relative;
  display: block;
  font-family: "Font Awesome 5 Free", "Font Awesome 5 Pro", FontAwesome;
  font-size: 20px;
  font-weight: 900;
}
@media (min-width: 990px) {
  .events-month-nav a.prev-month:before, .events-month-nav a.next-month:before {
    display: none;
  }
}
.events-month-nav a.prev-month span, .events-month-nav a.next-month span {
  display: none;
}
@media (min-width: 990px) {
  .events-month-nav a.prev-month span, .events-month-nav a.next-month span {
    display: block;
  }
}
.events-month-nav a.prev-month:before {
  content: "\f053";
}
.events-month-nav a.next-month:before {
  content: "\f054";
}

.events-calendar td.day {
  width: 100% !important;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
@media (min-width: 990px) {
  .events-calendar td.day {
    display: table-cell;
    width: auto !important;
    text-align: left;
  }
}
.events-calendar .cell-heading {
  display: inline-block;
  width: auto !important;
  min-width: 30px;
  text-align: left;
}
.events-calendar .cell-content {
  display: inline-flex !important;
  width: 100% !important;
  align-items: center;
}
@media (min-width: 990px) {
  .events-calendar .cell-content {
    display: block !important;
    width: auto !important;
  }
}
.events-calendar .cell-content span.date {
  width: 100%;
  max-width: 50px;
}
.events-calendar .cell-content .day-content {
  width: 100%;
  display: inline-flex;
  align-items: center;
  text-align: left;
}
.events-calendar .cell-content .day-content:before {
  content: "|";
  position: relative;
  display: block;
  padding-right: 15px;
}
@media (min-width: 990px) {
  .events-calendar .cell-content .day-content:before {
    display: none;
  }
}
.events-calendar .cell-content .day-content a {
  display: block;
}

.news-posts {
  position: relative;
}
.news-posts .news-post {
  width: 100%;
  border-bottom: 2px dashed #e6e6e6;
  padding-bottom: 25px;
}
.news-posts .news-post:last-of-type {
  border: none;
}
.news-posts .news-post .news-post-headline {
  margin-bottom: 5px;
}
.news-posts .news-post .news-post-headline a {
  border: none;
  color: #161925;
}
.news-posts .news-post .news-post-headline a:visited {
  color: #161925;
}
.news-posts .news-post p {
  margin: 30px 0;
}
.news-posts .news-post .news-post-image {
  display: none;
}
.news-posts .news-post .news-post-read-more {
  color: #037a8b;
  font-weight: 700;
}
.news-posts .news-post .news-post-read-more:hover, .news-posts .news-post .news-post-read-more:focus {
  color: black;
}

.news-search {
  display: flex;
  width: 100%;
  padding-bottom: 30px;
}
.news-search a {
  padding: 15px 35px;
  margin-left: 0;
  background: #e46e2e;
  border: 1px solid #e46e2e;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 14px;
  color: white;
}
.news-search a:hover, .news-search a:focus {
  background: #161925;
  color: white;
  text-decoration: none;
}
.news-search input[type=text] {
  display: block;
  padding: 10px;
  width: 100%;
  margin-right: 10px;
  border: 1px solid #cccccc;
  background: transparent;
  color: #333333;
  font-weight: 300;
  font-size: 16px;
  border-radius: 5px;
}
.news-search input[type=text]:focus {
  outline: none;
  border-color: black;
}

.gallery,
.albums {
  margin: 0 -15px;
}
.gallery h2,
.albums h2 {
  padding-left: 0 !important;
  border-bottom: 1px solid #037a8b;
  padding-bottom: 15px !important;
  margin-bottom: 15px;
  margin-top: 15px;
}
@media (min-width: 991px) {
  .gallery .albums .album,
.gallery .album .photos .photos,
.albums .albums .album,
.albums .album .photos .photos {
    width: 33.3333333333% !important;
  }
}
@media (min-width: 991px) {
  .gallery .album,
.gallery .photos,
.albums .album,
.albums .photos {
    justify-content: flex-start;
  }
}
.gallery .album a,
.gallery .photos a,
.albums .album a,
.albums .photos a {
  width: auto !important;
  height: auto !important;
}
.gallery .album .photo img,
.gallery .album img,
.gallery .photos .photo img,
.gallery .photos img,
.albums .album .photo img,
.albums .album img,
.albums .photos .photo img,
.albums .photos img {
  width: 100% !important;
  top: 0 !important;
  transform: none !important;
}
.gallery .album:after,
.gallery .photos:after,
.albums .album:after,
.albums .photos:after {
  content: "";
  flex: auto;
}

.album h2 {
  padding-left: 0 !important;
}
.album .photos {
  margin: 0 -15px;
}
.album .album-name {
  color: white;
  margin: 10px 0;
  font-size: 18px;
  font-weight: 700;
}