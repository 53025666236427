$base-font-size: 100%;
// assets path
$assets: '/Portals/_default/Skins/SC/assets/dist';

// fonts
$primary-font: mr-eaves-xl-sans, sans-serif;
$secondary-font: raleway, sans-serif;

//Font Weights
$light: 300;
$regular: 400;
$medium-weight: 500;
$bold: 700;
$heavy: 900;

// colors
$color01: #161925;
$color02: #037a8b;
$color03: #e46e2e;
$color04: #ffd79c;
$primary-color: #037a8b;
$primary-darker-color: #026a77;
$secondary-color: #818080;
$tertiary-color: #404040;

//Typography
$heading-color: #262626;
$heading-border-color: rgba(255, 255, 255, 0.25);

//Header
$header-background-color: #ffffff;

//Homepage Banner
$slider-buttons: #ffffff;
$slider-pagers: #ffffff;

//Typography
$heading-text: $color02;
$heading-text-dark: #333333;
$heading-text-light: $color04;
$paragraph-text: #333333;

//Links
$link-color: $color02;
$link-border-color: rgba(213, 213, 213, 0.5);
$link-border-hover-color: #d5d5d5;

//Butons
$button-color: #e46e2e;
$button-text: #ffffff;

//Socials
$social-link-color: #999999;

//Images
$figure-caption-border: rgba(3, 122, 139, 0.2);
$figure-caption-color: #333333;
$figure-caption-border-spacers: #ffffff;

//Tabs
$tabs-active: $color02;
$tabs-inactive: #ececec;
$tabs-text: #333333;
$tabs-border: #d9d9d9;
$tabs-active-border: $color02;

//Tables
$table-border: #d9d9d9;
$table-head-text: #ffffff;
$table-head-background: $color02;
$table-head-border: #026a77;

//Accordions
$accordion-border: #e6e6e6;
$accordion-border-hover: $color02;
$accordion-title: $color02;
$accordion-title-hover: #333333;
$accordion-span: $color02;
$accordion-span-hover: #333333;

//Top Navigation
$top-nav-link-color: #ffffff;

//Primary Navigation
$mobile-menu-bar-text: #595959;
$mobile-nav-border-color: #e5e5e5;
$mobile-subnav-color: #cccccc;
$mobile-subnav-background-color: rgba(0, 0, 0, 0.8);
$desktop-subnav-flyout-color: rgba(0, 0, 0, .85);
$desktop-subnav-flyout-item-hover-color: rgba(0, 0, 0, 0.5);
$nav-item-hover-color: #1a1a1a;

//Search
$search-background-color: #026a77;
$search-close-background-color: #026a77;
$search-input-border-color: rgba(255, 255, 255, 0.25);

//News
$news-date-color: #343434;
$news-blurb-color: #343434;
$news-view-all-color: #ffffff;
$news-border-color: #d5d5d5;
$news-item-border-bottom-color: #e6e6e6;
$featured-news-heading-color: $color04;

//Events
$events-title-color: $color04;
$events-date-color: #ffffff;
$events-blurb-color: #343434;
$events-read-more-color: #404040;
$events-view-all-color: $color01;
$events-border-color: #d5d5d5;
$events-item-border-bottom-color: #e6e6e6;
$events-featured-heading: $color02;
$events-slider-buttons: $color02;
$events-slider-pagers: $color02;

//Modals
$modal-heading-background: #666666;
$modal-heading-color: #ffffff;

//Forms
$form-field-border-color: #cccccc;
$form-field-text-color: #333333;
$form-checkbox-active-color: $color02;
$form-radio-button-color: $color02;
$form-radio-button-border-color: #cccccc;
$form-submit-button-color: $color02;
$form-submit-button-hover-color: #333333;
$form-submit-button-border-color: #cccccc;
$form-submit-button-text-color: #ffffff;
$form-submit-button-text-hover-color: #ffffff;

//Sidebar
$sidebar-background: #ffffff;

//Footer
$footer-background-color: $color02;
$footer-text-color: #ffffff;
$footer-link-color: $color04;
$footer-link-hover-color: #ffffff;
$footer-social-link-color: $color04;
$footer-social-link-hover-color: #ffffff;
$footer-link-after: #535353;
$footer-divider: #1d8996;
$footer-bottom-link-after: #d5d5d5;
$footer-column-borders: $color01;

//Subpage Banner
$subpage-banner-heading-color: $color02;